import { HeroProps } from './hero/Hero'
import { KnowledgeBankProps } from './knowledgebank/Knowledgebank'
import { useSiteContext } from '@dustin-web/microsite-components/src/hooks'
import { getCultureCode, getDefaultLanguage } from '@dustin-web/microsite-config'
import { SectionProps } from './startpage-wrapper'
import dynamic from 'next/dynamic'

type StartPageProps = {
  sections: Array<SectionProps>
  panelPath: string
}

const Hero = dynamic(() => import('./hero/Hero').then(mod => mod.Hero))
const Zone = dynamic(() =>
  import('@dustin-web/product-components/src/panels').then(mod => mod.Zone)
)
const Usp = dynamic(() => import('@dustin-web/content-components/src/usp/usp'))
const KnowledgeBank = dynamic(() =>
  import('./knowledgebank/Knowledgebank').then(mod => mod.KnowledgeBank)
)
const PopularBrands = dynamic(() => import('src/components/popular-brands'))
const Ads = dynamic(() => import('./ads/Ads').then(mod => mod.Ads))

export const StartPage = ({ sections, panelPath }: StartPageProps) => {
  const { site } = useSiteContext()
  const lang = getDefaultLanguage(site)
  const langCultureCode = getCultureCode(lang)

  return (
    <>
      {sections.map((section, index) => {
        switch (section.type) {
          case 'hero':
            return <Hero {...(section as HeroProps)} key={`hero-${index}`} />
          case 'zone':
            return (
              <Zone
                zone={section}
                panelPath={panelPath}
                langCultureCode={langCultureCode}
                key={`zone-${index}`}
                removeVerticalPadding={section.removeVerticalPadding}
                usePanelClasses={true}
              />
            )
          case 'usp':
            return <Usp key={`usp-${index}`} />
          case 'articles':
            return (
              <KnowledgeBank {...(section as KnowledgeBankProps)} key={`knowledgebank-${index}`} />
            )
          case 'brands':
            return <PopularBrands key={`popularbrands-${index}`} />
          case 'ads':
            return <Ads {...(section as HeroProps)} key={`ads-${index}`} />
        }
      })}
    </>
  )
}
