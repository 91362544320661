import { memo, useEffect, useState } from 'react'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks/'
import { usePreviewMode } from '../../utils/preview'
import {
  EditorialPageFragment,
  GetPageQuery,
  GetPageQueryVariables,
  GridBlock,
} from 'src/graphql/generated/graphql-types'
import { GetPage } from 'src/graphql/get-page.graphql'
import { PortalTarget } from '@dustin-web/microsite-components/src/portal'
import { StartPage } from './StartPage'
import { usePanels } from '@dustin-web/product-components/src/panels'
import { useSiteContext } from '@dustin-web/microsite-components/src/hooks'
import { useAuth } from '@dustin-web/microsite-components/src/auth'
import { useConsent } from '@dustin-web/microsite-components/src/hooks/use-consent'
import { StartPageKey, getStartpageVersion } from 'src/components/utils/getStartPageVersion'

// name inferred from umbraco, dont change
const HERO_BLOCK = 'BannerCarousel'
const ADS_CAROUSEL = 'AdsCarousel'

//constants
const PAGE_URI = '/new-startpage/startpage'
const GUIDE_CAT_TAGS = ['guide', 'gids']

//zones
const NEW_PRODUCTS = 'newProducts'
/* const TOP_SELLERS = 'topSellers' */
const BASED_ON_HISTORY = 'basedOnHistory'
const TOP_SELLING_CAMPAIGN = 'topSellingCampaign'
/* const PRIVATE_LABEL = 'privateLabelZone' */
/* const TOP_SELLING_GAMING = 'gaming' */
const VOXICON_PRIVATE_LABEL = 'voxiconLabelCategory'
const CIRAFON_PRIVATE_LABEL = 'cirafonLabelCategory'
const RECOMMENDED_FOR_YOU = 'recommendedForYou'
const POPULAR_RIGHT_NOW = 'popularRightNow'

export type SectionTypes = 'hero' | 'usp' | 'zone' | 'brands' | 'articles' | 'ads'

export type SectionProps = any & {
  type: SectionTypes
}

const StartpageWrapper = () => {
  const { isAuthenticated } = useAuth()
  const { personalisationConsentIsGiven } = useConsent()

  const [cookieConsentState, setCookieConsentState] = useState(
    personalisationConsentIsGiven ?? false
  )
  const personalizedCondition = isAuthenticated || cookieConsentState
  const { isBusiness } = useSiteContext()
  const { version, panelPath } = getStartpageVersion(personalizedCondition, isBusiness)

  const { preview } = usePreviewMode()

  // data fetching
  const startpageContent = useGraphql<GetPageQuery, GetPageQueryVariables>(GetPage, {
    variables: { uri: PAGE_URI, preview },
  })

  // TODO REINSTATE SKIP
  const { data: zonesData } = usePanels(panelPath, false)
  const editorialContent = startpageContent.data?.page as EditorialPageFragment
  const startpageBanners = editorialContent?.contentBlocks

  // top hero
  const heroBlocks = startpageBanners?.find(item => item?.properties?.name === HERO_BLOCK)
    ?.properties.blocks as GridBlock[]

  // ad-format
  const adsBlocks = startpageBanners?.find(item => item?.properties?.name === ADS_CAROUSEL)
    ?.properties.blocks as GridBlock[]

  useEffect(() => {
    setCookieConsentState(personalisationConsentIsGiven)
  }, [personalisationConsentIsGiven])

  if (startpageContent.error) {
    // eslint-disable-next-line no-console
    console.log(startpageContent.error)
  }

  const zones =
    zonesData &&
    zonesData?.panels?.zones?.map(zone => {
      return { type: 'zone', ...zone }
    })

  let sections: SectionProps[] = []

  // cms content
  const heroObj = { type: 'hero', blocks: heroBlocks }
  const adsObj = { type: 'ads', blocks: adsBlocks }
  const uspObj = { type: 'usp' }
  const brandObj = { type: 'brands' }
  const knowledgeBank = { type: 'articles', tags: GUIDE_CAT_TAGS }
  // elevate zones
  const newProducts = zones?.find(zone => zone.name === NEW_PRODUCTS)
  const voxiconPrivateLabel = zones?.find(zone => zone.name === VOXICON_PRIVATE_LABEL)
  const cirafonPrivateLabel = zones?.find(zone => zone.name === CIRAFON_PRIVATE_LABEL)
  /*   const topSellingGaming = zones?.find(zone => zone.name === TOP_SELLING_GAMING) */
  const basedOnHistory = zones?.find(zone => zone.name === BASED_ON_HISTORY)
  const recommendedForYou = zones?.find(zone => zone.name === RECOMMENDED_FOR_YOU)
  const popularRightNow = zones?.find(zone => zone.name === POPULAR_RIGHT_NOW)
  const topSellingCampaign = zones?.find(zone => zone.name === TOP_SELLING_CAMPAIGN)

  switch (version) {
    case StartPageKey.DEFAULTB2B:
      sections = [
        heroObj,
        { ...popularRightNow, removeVerticalPadding: false },
        uspObj,
        { ...newProducts, removeVerticalPadding: false },
        adsObj,
        brandObj,
        { ...voxiconPrivateLabel, removeVerticalPadding: false },
        { ...cirafonPrivateLabel, removeVerticalPadding: true },
        knowledgeBank,
      ]
      break
    case StartPageKey.PERSONALB2B:
      sections = [
        heroObj,
        { ...recommendedForYou, removeVerticalPadding: false },
        adsObj,
        { ...newProducts, removeVerticalPadding: false },
        uspObj,
        { ...basedOnHistory, removeVerticalPadding: false },
        brandObj,
        { ...voxiconPrivateLabel, removeVerticalPadding: false },
        { ...cirafonPrivateLabel, removeVerticalPadding: true },
        knowledgeBank,
      ]
      break
    case StartPageKey.DEFAULTB2C:
      sections = [
        heroObj,
        uspObj,
        { ...newProducts, removeVerticalPadding: false },
        adsObj,
        { ...topSellingCampaign, removeVerticalPadding: false },
        { ...voxiconPrivateLabel, removeVerticalPadding: false },
        { ...cirafonPrivateLabel, removeVerticalPadding: true },
        knowledgeBank,
      ]
      break
    case StartPageKey.PERSONALB2C:
      sections = [
        heroObj,
        { ...recommendedForYou, removeVerticalPadding: false },
        adsObj,
        { ...topSellingCampaign, removeVerticalPadding: false },
        uspObj,
        { ...basedOnHistory, removeVerticalPadding: false },
        { ...voxiconPrivateLabel, removeVerticalPadding: false },
        { ...cirafonPrivateLabel, removeVerticalPadding: true },
        knowledgeBank,
      ]
      break
  }

  return (
    <>
      <PortalTarget
        id="startpage-recently-viewed"
        className="startpage-recently-viewed"></PortalTarget>
      <StartPage sections={sections} panelPath={panelPath} />
    </>
  )
}
export default memo(StartpageWrapper)
