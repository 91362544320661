interface StartPage {
  version: StartPageKey
  panelPath: string
}

export enum StartPageKey {
  DEFAULTB2B = 'DEFAULT_B2B',
  PERSONALB2B = 'PERSONAL_B2B',
  DEFAULTB2C = 'DEFAULT_B2C',
  PERSONALB2C = 'PERSONAL_B2C',
}

export const getStartpageVersion = (isPersonalized: boolean, isBusiness: boolean): StartPage => {
  const DEFAULTB2B = '/personalization-test/default'
  const PERSONALB2B = '/personalization-test/personalized'
  const DEFAULTB2C = '/personalization-test-consumer/default'
  const PERSONALB2C = '/personalization-test-consumer/personalized'

  if (isPersonalized) {
    return isBusiness
      ? { version: StartPageKey.PERSONALB2B, panelPath: PERSONALB2B }
      : { version: StartPageKey.PERSONALB2C, panelPath: PERSONALB2C }
  }

  return isBusiness
    ? { version: StartPageKey.DEFAULTB2B, panelPath: DEFAULTB2B }
    : { version: StartPageKey.DEFAULTB2C, panelPath: DEFAULTB2C }
}
